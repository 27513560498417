import { Environment } from '../environment';

export const environment: Environment = {
    defaultLanguage: 'es-ES',
    supportedLanguages: ['es-ES', 'en-EN'],
    production: false,
    version: '1.0.0',
    serverUrl: 'https://admin.api.mille-canary.sandbox.cloud.codelabs.work/panel/',
    assetsUrl: 'https://api.mille-canary.sandbox.cloud.codelabs.work/',
    cacheDurationInMinutes: 10,
    userReloadIntervalSeconds: 300,
    isAdmin: true,
    build: {
        time: true,
        commit: true,
    },
    currency: 'EUR',
};
